import { prefixClass } from 'lib/utils';
import './STBTools.scss';
import { IconRefresh, IconDownload, IconValidate, IconAudit, IconTrashCan, IconChevronDown } from 'components/Icons';
import STBIcon from '../STBIcon';
import classNames from 'classnames';
import { HoneReportStatuses } from 'domain/models';
import { SyntheticEvent } from 'react';
import { ExportReport } from 'components/ExportReport';

type STBToolsProps = {
  adminMode?: boolean;
  reportId: string;
  status?: HoneReportStatus;
  csvReport?: any;
  generatePdf?: any;
  onRefresh?: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  onDownload?: () => void;
  onValidate?: () => void;
  onAudit?: () => void;
  onDelete?: () => void;
  onStatusChange?: (reportId: string, value: string) => void;
};

const STBTools = ({
  adminMode = false,
  reportId,
  csvReport,
  generatePdf,
  status = 'Published',
  ...events
}: STBToolsProps) => {
  const prefix = prefixClass('STB-Tools');
  const { onRefresh, onDownload, onValidate, onAudit, onDelete, onStatusChange } = events;
  const adminAllowed = adminMode && (onValidate || onAudit || onDelete || onStatusChange);

  const drawCommonTools = () => (
    <div className={classNames(prefix('common'), { admin: adminAllowed })}>
      {onRefresh && (
        <STBIcon
          tooltip="Refresh Report"
          handleClick={(e: SyntheticEvent<HTMLButtonElement, Event>) => onRefresh(e)}
          icon={<IconRefresh />}
        />
      )}
      {onDownload && <ExportReport csvReport={csvReport} pdfReport={generatePdf} showGrid={false} />}
    </div>
  );

  const drawAdminTools = () => (
    <div className={prefix('admin')}>
      {onValidate && <STBIcon tooltip="Validate Report" handleClick={onValidate} icon={<IconValidate />} />}
      {onAudit && <STBIcon tooltip="Run Audit on Report" handleClick={onAudit} icon={<IconAudit />} />}
      {onDelete && <STBIcon tooltip="Delete Report" handleClick={onDelete} icon={<IconTrashCan />} />}
      {onStatusChange && (
        <select value={status} onChange={e => onStatusChange(reportId, e.currentTarget.value)}>
          {Object.values(HoneReportStatuses).map((status, index) => (
            <option value={status} key={index}>
              {status}
            </option>
          ))}
        </select>
      )}
    </div>
  );

  return (
    <>
      {reportId && (
        <div className={prefix()}>
          {drawCommonTools()}
          {adminAllowed && drawAdminTools()}
        </div>
      )}
    </>
  );
};
export default STBTools;
