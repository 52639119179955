import { Whisper, Tooltip } from 'rsuite';
import classNames from 'classnames';
import { prefixClass } from 'lib/utils';

import './STBIcon.scss';
import { SyntheticEvent } from 'react';

type STBIconProps = {
  tooltip?: string;
  disabled?: boolean;
  icon: React.ReactElement;
  handleClick: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
};

const STBIcon = ({ tooltip, disabled, icon, handleClick }: STBIconProps) => {
  const prefix = prefixClass('STB-Icon');
  return (
    <Whisper placement="top" trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>} delayOpen={600} disabled={disabled}>
      <button
        className={classNames(prefix(), { disabled: disabled || !handleClick })}
        onClick={e => handleClick(e)}
        disabled={disabled}
      >
        {icon}
      </button>
    </Whisper>
  );
};

export default STBIcon;
