import { MouseEvent, MouseEventHandler, Suspense, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';
import BookkeeperForecastForm from './components/BookkeeperForecastForm';
import BookkeeperLocationForm from './components/BookkeeperLocationForm';
import BookkeeperTemplateForm from './components/BookkeeperTemplateForm';
import BookkeeperUsersForm from './components/BookkeeperUsersForm/BookkeeperUsersForm';

import { abilityCan } from 'lib/utils';
import HoneLocationsProvider from '../../../components/HoneLocations';

import BookkeeperMetrics from './components/BookkeeperMetrics';
import BookkeeperMetricsReport from './components/BookkeeperMetricsReport';
import useLocationRouting from 'hooks/useLocationRouting';

import { useHoneLocationUsers } from 'components/HoneLocationUsers';
import { HoneAbilityActions, HoneAbilitySubjects } from '@hone-automation/common';

import BookkeeperAccrualsSettingsForm from 'presentation/pages/bookkeeper/components/BookkeeperAccrualsSettingsForm';
import { useQueryState } from 'hooks/useQueryState';
import BookkeeperSlackChannels from './components/BookkeeperSlackChannels';
import NewLayout from '../../components/NewLayout/NewLayout';
import QboSettings from '../../components/CoAEditor/QboSettings';
import BookkeeperSetupCoA from './components/BookkeeperSetupCoA';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MENU_ICONS } from '../../../constants';

function Bookkeeper(): JSX.Element {
  useLocationRouting(false);
  const navigateTo = useNavigate();
  const [activeTab] = useQueryState('activetab', 'General');
  const { enableSlackChannels } = useFlags();

  const { userInfoHasLoaded, currentLocation, currentLocationAbilities } = useHoneLocationUsers();
  const isBookkeeper = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const tabs = [
    { name: 'General', component: <BookkeeperLocationForm /> },
    {
      name: 'Setup CoA',
      component: <BookkeeperSetupCoA />,
      screenSettings: <QboSettings />,
    },
    { name: 'Accruals', component: <BookkeeperAccrualsSettingsForm /> },
    { name: 'Users', component: <BookkeeperUsersForm /> },
    { name: 'Templates', component: <BookkeeperTemplateForm /> },
    { name: 'Forecast', component: <BookkeeperForecastForm /> },
    { name: 'Metrics', component: <BookkeeperMetrics /> },
    { name: 'Metrics Report', component: <BookkeeperMetricsReport /> },
  ];

  if (enableSlackChannels) tabs.push({ name: 'Slack Channels', component: <BookkeeperSlackChannels /> });

  useEffect(() => {
    const currentAbilitiesHasBeenLoaded = currentLocationAbilities.length > 0;
    if (!userInfoHasLoaded) return;
    if (!currentAbilitiesHasBeenLoaded) return;
    if (isBookkeeper) return;
    navigateTo('/app');
  }, [userInfoHasLoaded, isBookkeeper, currentLocationAbilities, history]);

  const handleClickTab = (tabName: string) => {
    navigateTo(`/app/location/${currentLocation?.locationId}/bookkeeper?activetab=${tabName}`);
  };

  const idxActiveTab = tabs.findIndex(tab => tab.name === activeTab);

  return (
    <Tabs selectedTabClassName="react-tabs-tab-selected" selectedIndex={Number(idxActiveTab)}>
      <NewLayout
        icon={MENU_ICONS.Bookkeeper}
        pageTitle="Accountant"
        className="bookkeeper"
        screenSettings={tabs[idxActiveTab].screenSettings}
        chartArea={
          <TabList>
            {tabs.map((tab, idx: number) => (
              <Tab
                selected={idx === idxActiveTab}
                className="react-tabs-tab"
                onClick={() => handleClickTab(tab.name)}
                key={`${tab.name}_${idx}`}
              >
                {tab.name}
              </Tab>
            ))}
          </TabList>
        }
      >
        <div className="bookkeeper-layout">
          <div className="Layout">
            <Suspense fallback={<Loading />}>
              <main className="Layout-main">
                <div className="Layout-content">
                  <HoneLocationsProvider>
                    {tabs.map((tab, idx: number) => (
                      <TabPanel key={`${tab.name}_${idx}`}>{tab.component}</TabPanel>
                    ))}
                  </HoneLocationsProvider>
                </div>
              </main>
            </Suspense>
          </div>
        </div>
      </NewLayout>
    </Tabs>
  );
}

export default Bookkeeper;
