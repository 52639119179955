import { Suspense, useEffect, useState, ChangeEvent, useMemo, useCallback, SyntheticEvent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import usePrevious from 'hooks/usePrevious';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { deleteReport, getAuditReport, useReportsStore } from 'hooks/useReportsStore';

import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import Loading from 'components/Loading';
import PDFObject from 'lib/components/PDFObject';
import ReportDataTable from './components/ReportDataTable';
import { HoneReportFormats, HoneReportTypes, MENU_ICONS } from '../../../constants';
import { trackError } from 'lib/analytics';
import {
  abilityCan,
  BALANCE_SHEET_PRESETS,
  currencyFormatter2Decimals,
  dismissToast,
  doPost,
  FIVE_SECONDS,
  isPeriodReport,
  percentageFormatter,
  reportingOrigin,
  reportTitleToUrl,
  showToast,
  sortReportTitles,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from 'lib/utils';

import LocationsAutocomplete from 'presentation/components/LocationsAutocomplete/LocationsAutocomplete';
import ReportGraph from './components/ReportGraph/ReportGraph';

import toast from 'react-hot-toast';
import { HoneReportStatuses, HoneReportTimeframes } from 'domain/models';
import { formatDate } from 'lib/utils';
import { fetchReportById, performReportUpdate, useActiveReportStore } from 'hooks/useActiveReportStore';
import { shallow } from 'zustand/shallow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { useQueryState } from 'hooks/useQueryState';
import { useReportGraphStore } from 'hooks/useReportGraphStore';

import { ReportNotFoundError } from 'domain/errors/report-not-found-error';
import { QueryStatus } from '@tanstack/react-query';
import qs from 'querystring';
import { useHighlightReportSelector } from 'hooks/useHighlightReportSelector';
import ReportDatesModal from 'components/ReportDatesModal';
import classNames from 'classnames';
import ModalCustomDateRange from './ModalCustomDateRange';
import {
  ComparisonRequest,
  ComparisonType,
  extractReportIds,
  HoneAbilityActions,
  HoneAbilitySubjects,
  ReportColumnTypes,
  ReportRequest,
} from '@hone-automation/common';
import { useHoneLocationUsers } from '../../../components/HoneLocationUsers';

import KSHeader from '../../components/KSHeader';
import STBTools from 'presentation/components/SectionToolbar/STBTools';
import jsPDF from 'jspdf';
import { getPrintingInformation } from 'lib';
import { SlidingPane } from 'components/SlidingPane';
import { differenceInSeconds, parseJSON } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import useActiveActions from 'hooks/useActiveActions';
import { getPrintedHeaderDate } from 'lib/reportUtils';
import { Report } from 'lib/honeTableUtils';
import { getHeaderFormattedText } from 'presentation/utils';
import ValidationReportResults from 'components/ValidationReportResults';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>This report is being updated, please try again in a few minutes.</p>
      <pre style={{ display: process.env.NODE_ENV === 'development' ? 'block' : 'none' }}>{error.message}</pre>
    </div>
  );
}

interface Props {
  reports: Map<string, HoneReportSummary[]>;
  columnHeaderTitle?: string;
  customHeaders?: string[];
  status: QueryStatus;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function ReportViewNoPl({ reports, columnHeaderTitle = '', customHeaders, status }: Props): JSX.Element {
  const { currentLocationAbilities } = useHoneLocationUsers();

  const { isHovered } = useHighlightReportSelector();

  const [showValidationPane, setShowValidationPane] = useState<boolean>(false);

  const [validateReportError, setvalidateReportError] = useState<string | null>(null);

  const [allReportsSelected, setAllReportsSelected] = useQueryState<boolean>('allReports', false);

  const { enableReportDateRange, enableNewPlReportModal, enableV3Location } = useFlags();

  const [auditMode, setAuditMode] = useQueryState('audit');
  const [total] = useQueryState('total');
  const [difference] = useQueryState('difference');
  const [validation] = useQueryState('validate');

  const { activeActions } = useActiveActions();
  const showPercentageValues = activeActions.includes('lines');
  const showMoneyValues = activeActions.includes('bars');

  const { currentLocationId, currentLocation } = useLocationsStore(
    state => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );

  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { locationId, reportId, reportType } = useParams<{
    locationId: string;
    reportId: string;
    reportType: string;
  }>();

  const originalReportType = reportType;

  const navigateTo = useNavigate();
  const isPLReport = ['income-statement', 'monthly', 'weekly'].includes(reportType!);

  const [chartCollapsed, setChartCollapsed] = useQueryState('chartCollapsed', 'false');

  const isBookkeeper = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const [, setDateRange] = useQueryState<string>('dateRange');
  const isBalanceSheetPreset = Object.keys(BALANCE_SHEET_PRESETS).includes(reportId!);

  const reportsError = useReportsStore(state => state.error);
  const { selectedReport, setOpenDateRangeModal } = useReportsStore(
    state => ({
      selectedReport: state.selectedReport,
      setOpenDateRangeModal: state.setOpenDateRangeModal,
    }),
    shallow
  );

  const isYtd = selectedReport?.timeframe === HoneReportTimeframes.YTDMonthly;

  const {
    validationReportData,
    yearPlotted,
    reportStatus,
    allCategories,
    getGroupReport,
    setYearPlotted,
    setRefreshingReport,
    rangeNoData,
    setStatus,
    currentReport,
    expandedRows,
  } = useActiveReportStore(
    state => ({
      yearPlotted: state.yearPlotted,
      setRefreshingReport: state.setRefreshingReport,
      setStatus: state.setStatus,
      rangeNoData: state.rangeNoData,
      reportStatus: state.status,
      allCategories: state.allCategories,
      getGroupReport: state.getGroupReport,
      setYearPlotted: state.setYearPlotted,
      currentReport: state.currentReport,
      expandedRows: state.expandedRows,
      validationReportData: state.currentReport?.validation,
    }),
    shallow
  );

  const [statusUpdates, setStatusUpdates] = useState<Record<string, string>>({});

  const allReports = reports.get(isPLReport ? 'pl' : reportType!) || [];
  const titles = uniqBy(allReports, 'title');

  const uniqueTitles = sortReportTitles(titles);

  const [titleFilter] = useQueryState<string>('type', reportTitleToUrl(uniqueTitles[0]?.title || ''));
  const [templateId] = useQueryState<string>('templateId');

  const filteredTitleFilter = templateId
    ? reportTitleToUrl(uniqueTitles.find(props => props.templateId === templateId)?.title || '')
    : titleFilter.split('&')[0];

  let filteredReports = allReports;
  if (isPLReport && titleFilter) {
    filteredReports = filteredReports.filter(({ title, timeframe }) => {
      if ((timeframe.includes('YTD') && reportType === 'income-statement') || timeframe.toLowerCase() === reportType) {
        return reportTitleToUrl(title) === filteredTitleFilter;
      }
      return false;
    });
  }

  const prevReportType = usePrevious(reportType);
  const dateRange = urlParams.get('dateRange');

  useEffect(() => {
    rangeNoData && navigateTo(`/app/location/${currentLocationId}/report/${reportType}`, { replace: true });
  }, [rangeNoData]);

  const oldReportId = usePrevious(reportId);

  const toggleValidate = () => {
    setShowValidationPane(false);
  };

  const handleValidateReport = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const validate = searchParams.get('validate');
    if (validate) {
      setShowValidationPane(true);
      return;
    }
    searchParams.set('validate', 'true');
    const newSearch = searchParams.toString();

    navigateTo({
      search: newSearch,
    });
  };

  const toggleAuditMode = () => {
    const nextState = auditMode === 'true' ? 'false' : 'true';
    setAuditMode(nextState);
    return nextState;
  };

  const handleAuditReport = async (toggle = true) => {
    let next: 'true' | 'false' = 'false';
    if (toggle) {
      next = toggleAuditMode();
    }
    if (!toggle || next === 'true') {
      await getAuditReport(selectedReport?.id as string);
    } else {
      useReportsStore.setState({ auditReport: null });
    }
  };

  useEffect(() => {
    if (auditMode === 'true') {
      handleAuditReport(false);
    }
  }, []);

  useEffect(() => {
    if (validation) {
      setShowValidationPane(true);
    }
  }, [validation]);

  const handleRefreshReport = async (e: SyntheticEvent<HTMLButtonElement>) => {
    try {
      const target = e.currentTarget;
      // allow only every two minutes

      const selectedReportId = selectedReport?.id;
      const updatedAt = currentReport?.updatedAt ? currentReport?.updatedAt : selectedReport?.updatedAt;
      const difference = updatedAt ? differenceInSeconds(new Date(), parseJSON(updatedAt)) : 0;

      const refreshTimeAllowed = 60 * 5;
      if (difference < refreshTimeAllowed) {
        showToast(`Report can be refreshed again in ${refreshTimeAllowed - difference} seconds`, TOAST_ERROR);
        return;
      }

      if (target) {
        target.disabled = true;
      }

      if (!selectedReportId) {
        return;
      }
      setRefreshingReport(true);
      const periodStartEndDates = currentReport?.dates;
      const extractedReportIds = extractReportIds(periodStartEndDates!);

      const reportIds = extractedReportIds.length > 0 ? extractedReportIds : [selectedReportId];
      await performReportUpdate(selectedReport!, reportIds, enableNewPlReportModal);

      if (target) {
        target.disabled = false;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error('Error refreshing report');
    } finally {
      setRefreshingReport(false);
    }
  };

  const handleDeleteReport = async () => {
    const selectedReportId = selectedReport?.id;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3 className="text-center">Are you sure you want to delete this report?</h3>
            <button className="button ReportControls-button mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button ReportControls-button-alert"
              onClick={() => {
                onClose();
                const toastId = showToast(
                  'Deleting report, will take a moment to complete',
                  TOAST_DEFAULT,
                  FIVE_SECONDS
                );
                deleteReport(selectedReportId as string)
                  .then(() => {
                    dismissToast(toastId);
                    showToast('Report deleted', TOAST_SUCCESS, FIVE_SECONDS);
                    setTimeout(() => {
                      navigateTo(`/app/location/${locationId}`, { replace: true });
                    }, 2000);
                  })
                  .catch((error: any) => {
                    trackError({ error: error as Error });
                  });
              }}
            >
              Delete Report
            </button>
          </div>
        );
      },
    });
  };

  const reportDates = useMemo(() => {
    if (!dateRange) {
      return currentReport && currentReport.dates;
    }
    if (total || difference) {
      return (currentReport as any).dates;
    }
    const tempReportDates = [...currentReport!.dates];
    tempReportDates.pop();
    return tempReportDates;
  }, [new Date()]);

  const reportDatesHeaders = useMemo(() => {
    if (!reportDates) return;
    const reportDatesHeaderFormatted = reportDates.map((date: any) => {
      const hasSubheader =
        enableNewPlReportModal &&
        ((date.type && date.type === ReportColumnTypes.Data) || date.type === ReportColumnTypes.Budget);

      const subheaderLabel = hasSubheader && date.type === ReportColumnTypes.Data ? 'ACTUAL' : 'BUDGET';

      const headerFormatted = getHeaderFormattedText(date, currentReport as NestedHoneReport, yearPlotted, false);

      const getPrintedDate = getPrintedHeaderDate(headerFormatted, null, false);

      return (getPrintedDate as string).concat(hasSubheader ? ` ${subheaderLabel}` : '');
    });
    return reportDatesHeaderFormatted;
  }, [reportDates]);

  const getReportChildren = (reports: NestedHoneReportSection[], flatArray: Report[] = []): Report[] => {
    for (const report of reports) {
      const maskedTitle = Array(report.level).fill(' * ').join('') + report.title;
      const maskedPercentageTitle = Array(report.level).fill(' * ').join('') + '% OF ' + report.title;

      flatArray.push({
        ...report,
        ...report.data?.map(data => (showMoneyValues ? currencyFormatter2Decimals(data?.amount) : '')),
        title: maskedTitle,
      });

      if (report.title !== '' && showPercentageValues) {
        flatArray.push({
          ...report,
          ...report.data?.map(data => percentageFormatter(data?.perc || 0)),
          title: maskedPercentageTitle,
        });
      }

      if (report.children) {
        getReportChildren(report.children, flatArray);
      }
    }
    return flatArray;
  };

  const csvReport = useMemo(() => {
    const reportName = `${selectedReport?.startDateText} - ${selectedReport?.endDateText}`;
    const headers =
      reportDatesHeaders?.map((date: string, index: number) => {
        return {
          label: date,
          key: index.toString(),
        };
      }) || [];

    return {
      filename: `Hone Report: ${currentLocation?.name} ${selectedReport?.title} - ${reportName}.csv`,
      headers: [{ label: 'Title', key: 'title' }, { label: 'GL Code', key: 'glCode' }, ...headers],
      data: currentReport ? getReportChildren(currentReport?.sections) : [],
    };
  }, [
    getReportChildren,
    selectedReport,
    selectedReport?.endDateText,
    selectedReport?.startDateText,
    selectedReport?.title,
  ]);

  const generatePdf = () => {
    try {
      const doc = new jsPDF({ orientation: 'l' });

      const rows = getPrintingInformation(currentReport?.sections, expandedRows, showMoneyValues, showPercentageValues);
      const columns: string[] = ['Accounts', ...reportDatesHeaders];

      (doc as any).autoTable({
        html: '#my-table',
        styles: {
          fontSize: 50,
          cellWidth: 'wrap',
        },
        columnStyles: {
          1: { columnWidth: 'auto' },
        },
      });

      (doc as any).autoTable(columns, rows);
      const reportName = `${selectedReport?.startDateText} - ${selectedReport?.endDateText}`;
      const filename = `KitchenSync Report: ${currentLocation?.name} ${selectedReport?.title} - ${reportName}.pdf`;
      doc.save(filename);
    } catch (error) {
      showToast('An error has occurred, please contact admin', TOAST_ERROR, FIVE_SECONDS);
    }
  };

  useEffect(() => {
    if (!isYtd) return;
    if (!selectedReport) return;
    const yearToPlot = Number(selectedReport.startDateText.split('-')[0]);
    setYearPlotted(yearToPlot);
  }, [selectedReport]);

  const totalParsed = urlParams.get('total');
  const differenceParsed = urlParams.get('difference');
  const validateParsed = urlParams.get('validate');

  useEffect(() => {
    if (dateRange || (isBalanceSheetPreset && oldReportId !== reportId)) {
      let type: 'P&L Comparison' | 'Balance Sheet' = 'P&L Comparison';
      let [startDate, endDate] = urlParams.get('dateRange')?.split(',') || [];
      let limit;
      if (isBalanceSheetPreset) {
        type = 'Balance Sheet';
        const preset = BALANCE_SHEET_PRESETS[reportId as keyof typeof BALANCE_SHEET_PRESETS];
        startDate = preset.startDateText;
        endDate = preset.endDateText;
        limit = preset.limit;
      }

      const totalParsed = urlParams.get('total');
      const differenceParsed = urlParams.get('difference');
      const comparisonType = urlParams.get('comparisonType');
      const comparisonPeriods = urlParams.get('comparisonPeriods');
      const timeframe = urlParams.get('timeframe');

      const comparison: ComparisonRequest = {
        comparisonType: comparisonType as ComparisonType,
        comparisonPeriods: Number(comparisonPeriods),
      };
      (async () => {
        const reportPayload: ReportRequest = {
          type,
          locations: [String(currentLocationId) || ''],
          templateId: templateId,
          startDate,
          endDate,
          total: totalParsed === 'true',
          difference: differenceParsed === 'true',
          limit,
        };

        if (comparisonType) {
          reportPayload.comparison = comparison;
        }

        const response = await getGroupReport(reportPayload);

        if (response === 'error') {
          navigateTo(`/app/location/${currentLocationId}`, { replace: true });
        }
      })();
    }
  }, [dateRange, isBalanceSheetPreset, reportId, oldReportId, totalParsed, differenceParsed]);

  useEffect(() => {
    if (!validateParsed) return;

    (async () => {
      const parsedReportId = selectedReport && selectedReport?.id;
      useActiveReportStore.setState({ status: 'loadingDateRange', refreshingReport: false });
      const report = allReports.find(report => report.id === parsedReportId);
      const response = fetchReportById(report);
      if (validateParsed) {
        await toast.promise(
          response,
          {
            loading: 'Validating report, will take a few seconds...',
            success: 'Report, successfully validated',
            error: 'Error validating report',
          },
          {
            style: {
              maxWidth: 'max(50vw, 350px)',
            },
          }
        );
      }
    })();
  }, [validateParsed]);

  useEffect(() => {
    const clonedAllReports = cloneDeep(allReports);
    if (!reportId && (reportType === 'ap-aging' || reportType === 'cash-flow')) {
      const reportsByType = reports.get(reportType);
      const report = reportsByType && reportsByType[0];
      report && fetchReportById(report);
      return;
    }
    const parsedReportId = reportId && reportId.includes('?') ? reportId.split('?')[0] : reportId;
    const report = clonedAllReports.find(report => {
      return report.id === parsedReportId;
    });

    report && fetchReportById(report);
  }, [reportId, allReports, reportType]);

  useEffect(() => {
    if (!allReports.length) return;
    let selectedReport: HoneReportSummary | undefined;
    if (reportType) {
      if (reportType === 'balance-sheet' && typeof reportId === 'undefined') {
        navigateTo(`/app/location/${locationId}/report/balance-sheet/6-months`, { replace: true });
        return;
      }

      selectedReport = reports.get(reportType)![0];
      if (prevReportType !== reportType) {
        setYearPlotted(new Date().getFullYear());
      }

      if (reportId) {
        if (Object.keys(BALANCE_SHEET_PRESETS).includes(reportId)) {
          useReportsStore.setState({ selectedReport });
          return;
        }

        const parsedReportId = reportId.includes('?') ? reportId.split('?')[0] : reportId;
        if (templateId && !dateRange) {
          selectedReport = reports.get(reportType)!.find(report => {
            return report.id === parsedReportId && report.templateId === templateId;
          });
        } else {
          const reportFound = reports.get(reportType)!.find(report => report.id === parsedReportId);

          if (!dateRange && !reportFound) {
            Sentry.captureException(`Report not found ${reportId}`);
            setTimeout(() => {
              showToast(
                "The report you are trying to access isn't available. Please click on another report to access the data you want.",
                TOAST_ERROR
              );
            }, 1);

            return;
          }
          selectedReport = reportFound;
        }
      } else {
        if (titleFilter && !dateRange) {
          selectedReport = reports
            .get(reportType)!
            .find(report => filteredTitleFilter === reportTitleToUrl(report.title));
        }
      }
      if (!selectedReport && !dateRange) {
        try {
          setTimeout(() => {
            showToast(
              "The report you are trying to access isn't available. Please click on another report to access the data you want.",
              TOAST_ERROR,
              FIVE_SECONDS
            );
          }, 1);

          const lookingForId = reportId || titleFilter;
          throw new ReportNotFoundError(lookingForId);
        } catch (error) {
          Sentry.captureException(error);
          navigateTo(`/app/location/${currentLocationId}`);
        }
      }

      if (useReportsStore.getState().selectedReport?.id !== selectedReport?.id) {
        useReportsStore.setState({ selectedReport });
      }
      if (selectedReport?.id) {
        useActiveReportStore.setState({ currentReportId: selectedReport.id });
      }
    }
  }, [allReports, reports, reportId, reportType]);

  const setReportStatus = (reportId: string, status: string) => {
    return doPost(`${reportingOrigin()}/reports/status`, { reportId, status });
  };

  const handleReportStatusChange = (reportId: string, status: string) => {
    const updater = setReportStatus(reportId, status).catch(error => {
      trackError({ error: error as Error });
      // reset back to previous status
      if (selectedReport) {
        setStatusUpdates({ ...statusUpdates, ...{ [reportId]: selectedReport.status } });
      }
    });
    toast.promise(
      updater,
      {
        loading: 'Updating...',
        success: `Updated report status to ${status}`,
        error: 'Error updating report status',
      },
      {
        style: {
          maxWidth: 'max(50vw, 350px)',
        },
        success: {
          duration: 4000,
        },
      }
    );
    setStatusUpdates({ ...statusUpdates, ...{ [reportId]: status } });
  };

  const years = useMemo(
    () =>
      allReports.map(report => ({
        period: new Date(report.endDate).getFullYear(),
        reportId: report.id,
      })),
    [allReports]
  );

  const handleReportChange = (newReportId: string) => {
    if (newReportId === 'custom_range') {
      setOpenDateRangeModal(true);
      return;
    }

    setStatus('loading');
    setDateRange(undefined);

    const yearPlotted = years.find(year => year.reportId === newReportId);
    yearPlotted?.period && setYearPlotted(yearPlotted?.period);

    const { dateRange, total, difference, ...queryParams } = qs.parse(location.search.replace('?', ''));
    const newQueries = { ...queryParams };

    if (templateId) {
      newQueries.templateId = templateId;
    }
    useReportsStore.setState({ selectedReport: undefined });
    useActiveReportStore.setState({ currentReport: undefined });
    navigateTo({
      pathname: `/app/location/${currentLocationId}/report/${reportType}/${newReportId}`,
      search: qs.stringify(newQueries),
    });
  };

  const handleTitleFilterChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value;
    const isPeriodReport = e.target.options[e.target.selectedIndex].dataset.period;
    let timeframe = e.target.options[e.target.selectedIndex].dataset.timeframe;
    timeframe = timeframe!.includes('ytd') ? 'income-statement' : timeframe;
    if (dateRange && !isPeriodReport) {
      const totalParsed = urlParams.get('total');
      const differenceParsed = urlParams.get('difference');
      const newDateRange = urlParams.get('dateRange');

      const queryParams = qs.parse(location.search.replace('?', ''));

      const newQueries = {
        ...queryParams,
        dateRange: newDateRange,
        templateId,
        total: totalParsed,
        difference: differenceParsed,
      };

      navigateTo({
        pathname: `/app/location/${currentLocationId}/report/${originalReportType}`,
        search: qs.stringify(newQueries),
      });

      const response = await getGroupReport({
        baseId: '',
        type: 'P&L Comparison',
        locations: currentLocationId ? [String(currentLocationId)] : [],
        templateId: String(templateId),
        startDate: newDateRange?.split(',')[0],
        endDate: newDateRange?.split(',')[1],
        total: totalParsed === 'true',
        difference: differenceParsed === 'true',
      });

      if (response === 'error') {
        navigateTo(`/app/location/${currentLocationId}`, { replace: true });
      }
      useReportsStore.setState({ selectedReport: undefined });
      return;
    }
    if (templateId) {
      const { dateRange, total, difference, ...queryParams } = qs.parse(location.search.replace('?', ''));
      let newQueries: any = { ...queryParams, templateId };
      if (!isPeriodReport) {
        newQueries = { ...newQueries, dateRange, total, difference };
      }
      useReportsStore.setState({ selectedReport: undefined });
      navigateTo({
        pathname: `/app/location/${currentLocationId}/report/${timeframe}`,
        search: qs.stringify(newQueries),
      });
      return;
    }
  };

  // Disable smoothing for Balance Sheet and Cash Flow
  useEffect(() => {
    if (selectedReport && selectedReport?.type === HoneReportTypes.IncomeStatement)
      useReportGraphStore.setState({ extraReportId: undefined });
    if (
      selectedReport &&
      (selectedReport.type === HoneReportTypes.BalanceSheet || selectedReport.type === HoneReportTypes.CashFlow)
    ) {
      useActiveReportStore.setState({ smoothingEnabled: false });
    }
  }, [selectedReport]);

  const hasCharts =
    !isEmpty(allCategories) && selectedReport && ['P&L Comparison', 'Income Statement'].includes(selectedReport?.type);

  const showCharts = chartCollapsed === 'false' && auditMode !== 'true' && hasCharts && reportType !== 'ap-aging';

  const enableDateRangeBasedOnType = selectedReport?.type === HoneReportTypes.PLComparison;

  const getFilteredReportsOptions = () => {
    if (enableReportDateRange && enableDateRangeBasedOnType && selectedReport?.type === HoneReportTypes.PLComparison) {
      return [{ id: 'custom_range', startDateText: 'Custom Range', endDateText: '' }, ...filteredReports];
    }

    if (selectedReport?.type === HoneReportTypes.BalanceSheet) {
      return [
        ...Object.entries(BALANCE_SHEET_PRESETS as unknown as Record<string, string>).map(([id, startDateText]) => ({
          id,
          startDateText,
          endDateText: '',
        })),
        ...filteredReports,
      ];
    }

    return filteredReports;
  };
  const summaryReport = useReportGraphStore(state => state.summaryReport);

  const graphWidth = useMemo(() => {
    if (isYtd) {
      return 1200;
    }

    return 60 + 175 * ((summaryReport?.reportLabels || []).length ?? 3);
  }, [isYtd, selectedReport?.id, summaryReport?.reportLabels]);

  const filteredReportsOptions = getFilteredReportsOptions();

  const getSelectedReportId = useCallback(() => {
    if (reportId) return reportId.includes('?') ? reportId.split('?')[0] : reportId;
    if (dateRange) return 'custom_range';

    if (status === 'pending') return 'loading';

    if (selectedReport?.type === HoneReportTypes.PLComparison) {
      // check if index exists in array of reports
      return filteredReportsOptions[0].id;
    }

    const index = filteredReportsOptions.findIndex(report => report.id === selectedReport?.id);
    if (index > -1) {
      return filteredReportsOptions[index].id;
    }
    return filteredReportsOptions[0].id;
  }, [selectedReport]);

  if (reportsError) {
    return (
      <div className="report-view add-padding">
        <div className="report-view--header">
          <div />
          <div className="report-view--header__location">
            <LocationsAutocomplete />
          </div>
        </div>
        <div className="report-view-container h-100 center">{reportsError}</div>
      </div>
    );
  }

  if (reportStatus === 'loadingDateRange' || !selectedReport || !currentReport) {
    return (
      <div className="h-100">
        <Loading />
      </div>
    );
  }

  const showAllButton =
    selectedReport?.type === HoneReportTypes.PLComparison && selectedReport?.timeframe === HoneReportTimeframes.Weekly;

  const canReadBookkeeperControls = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const Icon: any = selectedReport && selectedReport.type in MENU_ICONS ? MENU_ICONS[selectedReport.type] : null;
  const rTitle: string =
    selectedReport && typeof selectedReport.type === 'string' && selectedReport.type === 'P&L Comparison'
      ? 'P&L'
      : selectedReport.type;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="report-view">
        <KSHeader />
        <div className="report-view--header add-padding">
          <div className="report-view--header__item">
            <span className="header-icon header-icon-bottom ">
              <Icon />
            </span>
            <div className="report-view-header-title">{rTitle}</div>
            {selectedReport && (
              <div className="report-view-container-title">
                {isPLReport && uniqueTitles.length > 1 && (
                  <select value={templateId} onChange={handleTitleFilterChange}>
                    {uniqueTitles.map((props, index) => {
                      const { type, title, templateId, timeframe } = props;
                      const _isPeriodReport = isPeriodReport(props);
                      return (
                        <option
                          value={templateId}
                          data-timeframe={timeframe.toLowerCase()}
                          data-period={_isPeriodReport}
                          key={`${type}-${index}`}
                        >
                          {title}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            )}

            {reportType !== 'ap-aging' ? (
              <ReportDatesModal
                key={dateRange}
                uniqueTitles={uniqueTitles}
                timeframe={selectedReport.timeframe}
                selectedReportTitle={selectedReport.title}
                onLoadMore={() => setAllReportsSelected('true')}
                selectedOption={getSelectedReportId()}
                onReportChange={handleReportChange}
                options={filteredReportsOptions}
              />
            ) : (
              <div
                className={classNames('reports-selector-container', {
                  hovered: isHovered,
                })}
              >
                <select value={getSelectedReportId()} onChange={e => handleReportChange(e.currentTarget.value)}>
                  {filteredReportsOptions.length > 0 ? (
                    filteredReportsOptions.map(({ id, startDateText, endDateText }) => {
                      return (
                        <option value={id} key={id}>
                          {id === 'custom_range' &&
                            `Custom Range ${dateRange ? '[' + dateRange?.split('&')[0] + ']' : ''} `}
                          {Object.keys(BALANCE_SHEET_PRESETS).includes(id)
                            ? BALANCE_SHEET_PRESETS[id as keyof typeof BALANCE_SHEET_PRESETS].label
                            : !['custom_range'].includes(id) &&
                              `${formatDate(new Date(startDateText + 'T00:00:00'))} - ${formatDate(
                                new Date(endDateText + 'T00:00:00')
                              )}`}
                        </option>
                      );
                    })
                  ) : (
                    <option value="loading">Loading...</option>
                  )}
                </select>
                {showAllButton && (
                  <button
                    disabled={allReportsSelected}
                    className="btn btn-secondary btn-secondary-small"
                    onClick={() => setAllReportsSelected('true')}
                  >
                    All
                  </button>
                )}
                <ModalCustomDateRange uniqueTitles={uniqueTitles} />
              </div>
            )}
          </div>
          <div className="report-view--header__item">
            <div>
              {canReadBookkeeperControls && selectedReport && (
                <STBTools
                  adminMode={isBookkeeper}
                  reportId={selectedReport?.id || ''}
                  status={(statusUpdates[selectedReport.id] || selectedReport.status) as HoneReportStatus}
                  onRefresh={(e: SyntheticEvent<HTMLButtonElement, Event>) => handleRefreshReport(e)}
                  onDownload={() => {}}
                  onValidate={handleValidateReport}
                  onAudit={handleAuditReport}
                  onStatusChange={handleReportStatusChange}
                  onDelete={handleDeleteReport}
                  csvReport={csvReport}
                  generatePdf={generatePdf}
                />
              )}
            </div>
            {!enableV3Location && (
              <div className="report-view--header__location">{!isMobile && <LocationsAutocomplete />}</div>
            )}
          </div>
        </div>
        {selectedReport && (
          <div className="report-view-container">
            {showCharts && (
              <ReportGraph
                graphWidth={graphWidth}
                isYTD={isYtd}
                selectedReport={selectedReport}
                reports={filteredReports}
                extraReportDataSummary={null}
                timeframe={selectedReport.timeframe}
                onReportChange={handleReportChange}
              />
            )}
            <Suspense
              fallback={
                <div className="h-100 center">
                  <Loading />
                </div>
              }
            >
              {selectedReport.format === HoneReportFormats.PDF && <PDFObject url={selectedReport.dataLoc} />}
              {selectedReport.format === HoneReportFormats.JSON && (
                <ReportDataTable
                  report={selectedReport}
                  columnHeaderTitle={columnHeaderTitle}
                  customHeaders={customHeaders || []}
                  hasCharts={!!showCharts}
                  selectedReportType={selectedReport.type}
                  selectedReportId={selectedReport.id}
                />
              )}
            </Suspense>
          </div>
        )}
        <SlidingPane
          onRequestClose={toggleValidate}
          title={`Validation results`}
          width={'75%'}
          isOpen={showValidationPane}
        >
          {validateReportError && <span>{validateReportError}</span>}
          {validationReportData && <ValidationReportResults reportData={validationReportData} />}
        </SlidingPane>
      </div>
    </ErrorBoundary>
  );
}

export default ReportViewNoPl;
