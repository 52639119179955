import { prefixClass } from 'lib/utils';
import './SFActions.scss';
import {
  FilterActuals,
  FilterBudget,
  FilterDifference,
  FilterPercentage,
  IconChart,
  IconSmooth,
} from 'components/Icons';
import IconButton from './IconButton/IconButton';
import { useEffect, useState, useCallback } from 'react';

import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { useQueryState } from 'hooks/useQueryState';
import useActiveActions from 'hooks/useActiveActions';
import { HoneReportTypes, ReportView } from 'domain/models';
import { ReportType } from '../SearchAndFilter';
import { useLocationsStore } from 'hooks/useLocationsStore';

type SFActionsProps = {
  reportType: ReportType;
  selectedReportType: HoneReportType;
};

const SFActions = ({ reportType, selectedReportType }: SFActionsProps) => {
  const prefix = prefixClass('sf-actions');

  // Getting URL Params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const difference = false; //urlParams.get('difference') === 'true';
  const budgetInclude = urlParams.get('budgetInclude') === 'true';
  const [querySmoothing, setQuerySmoothing] = useQueryState('smoothing');
  const [chartCollapsed, setChartCollapsed] = useQueryState('chartCollapsed', 'true');

  const [differenceCollapsed, setDifferenceCollapsed] = useQueryState(
    'differenceCollapsed',
    difference ? 'false' : 'true'
  );
  const [budgetCollapsed, setBudgetCollapsed] = useQueryState('budgetCollapsed', budgetInclude ? 'false' : 'true');

  const currentLocation = useLocationsStore(state => state.currentLocation);

  // Table Column filtering
  const { activeActions, toggleAction } = useActiveActions();
  const [reportViewState, setReportViewState] = useState({});
  const showPercentageValues = activeActions.includes('lines');
  const showMoneyValues = activeActions.includes('bars');

  // Component Inner States
  const [actualState, setActualState] = useState(showMoneyValues);
  const [percentageState, setPercentageState] = useState(showPercentageValues);
  const [budgetState, setBudgetState] = useState(budgetInclude);
  const [deviationState, setDeviationState] = useState(difference);
  const [smoothingState, setSmoothingState] = useState(querySmoothing === 'true');
  const [graphState, setGraphState] = useState(!chartCollapsed);

  //Smoothig
  const storeSmoothing = useActiveReportStore(state => state.smoothingEnabled);
  const toggleSmoothing = useActiveReportStore(state => state.toggleSmoothing);
  const smoothingEnabled = querySmoothing === 'true';

  const handleSmoothing = () => {
    setQuerySmoothing((!(querySmoothing === 'true')).toString());
    toggleSmoothing();
    setSmoothingState(s => !s);
  };

  useEffect(() => {
    if (smoothingEnabled && !storeSmoothing) {
      toggleSmoothing();
    }
  }, [smoothingEnabled, storeSmoothing, toggleSmoothing]);

  // Chart Collapsing
  const toggleChartCollapse = () => {
    setChartCollapsed(chartCollapsed === 'true' ? 'false' : 'true');
    setGraphState(g => !g);
  };

  // Table Column filtering

  const handleClickTab = useCallback(
    (option: ReportView) => {
      if (option === ReportView.money) {
        toggleAction('bars');
        setReportViewState({
          ...reportViewState,
          showMoneyValues: !showMoneyValues,
        });
        return;
      }
      if (option === ReportView.percentage) {
        toggleAction('lines');
        setReportViewState({
          ...reportViewState,
          showPercentageValues: !showPercentageValues,
        });
        return;
      }
    },
    [toggleAction]
  );

  const handleTableFiltering = (filter: string) => {
    switch (filter) {
      case 'actual':
        // We are going to deactivate actuals, but if percentage is off we need to activate it otherwise we will show empty data
        /*       if (actualState && !percentageState) {
          setPercentageState(true);
          setReportViewState({
            ...reportViewState,
            showPercentageValues: true,
          });
        } */
        setActualState(a => !a);
        handleClickTab(ReportView.money);
        break;
      case 'percentage':
        // We are going to deactivate percentage, but if actual is off we need to activate it otherwise we will show empty data
        /*         if (percentageState && !actualState) {
          setActualState(true);
          setReportViewState({
            ...reportViewState,
            showMoneyValues: true,
          });
        } */
        setPercentageState(p => !p);
        handleClickTab(ReportView.percentage);
        break;
      case 'budget':
        setBudgetState(b => !b);
        setBudgetCollapsed(budgetCollapsed === 'true' ? 'false' : 'true');
        break;
      case 'deviation':
        setDeviationState(d => !d);
        setDifferenceCollapsed(differenceCollapsed === 'true' ? 'false' : 'true');
        break;
    }
  };

  // Pay
  const showPayBillsButton = reportType === 'Payable' && currentLocation?.paymentLink;

  const handlePaymentLinkClick = () => {
    window.open(currentLocation?.paymentLink, '_blank');
  };

  const getTooltipText = (state: boolean, label: string) => {
    return `${state ? 'Hide' : 'Show'} ${label}`;
  };

  return (
    <div className={prefix()}>
      {reportType === 'Report' && (
        <>
          <IconButton
            tooltip={getTooltipText(actualState, 'Actual Values')}
            status={actualState}
            icon={<FilterActuals />}
            onClick={() => handleTableFiltering('actual')}
          />
          <IconButton
            tooltip={getTooltipText(percentageState, 'Percentage Values')}
            status={percentageState}
            icon={<FilterPercentage />}
            onClick={() => handleTableFiltering('percentage')}
          />
          {budgetInclude && (
            <IconButton
              tooltip={getTooltipText(budgetState, 'Budget Values')}
              status={budgetState}
              icon={<FilterBudget />}
              onClick={() => handleTableFiltering('budget')}
            />
          )}
          {difference && (
            <IconButton
              tooltip={getTooltipText(deviationState, 'Difference/ Budget Variance')}
              status={deviationState}
              icon={<FilterDifference />}
              onClick={() => handleTableFiltering('deviation')}
            />
          )}
          <IconButton
            tooltip={getTooltipText(smoothingState, 'smoothing')}
            status={smoothingState}
            icon={<IconSmooth />}
            iconSize={26}
            boundary={true}
            onClick={handleSmoothing}
          />
          <IconButton
            tooltip={getTooltipText(graphState, 'graph')}
            status={graphState}
            icon={<IconChart />}
            boundary={true}
            onClick={toggleChartCollapse}
          />
        </>
      )}
      {reportType === 'Payable' && showPayBillsButton && (
        <IconButton tooltip="Pay Bills" label="Pay Bills" boundary={true} onClick={handlePaymentLinkClick} />
      )}
    </div>
  );
};
export default SFActions;
