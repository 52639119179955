import { IconDown, IconDownload, IconExportCSV, IconExportPDF } from './Icons';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { useEffect, useRef, useState, MouseEvent } from 'react';
import { CSVLink } from 'react-csv';
import { getConnectionLocation } from 'lib/utils';
import STBIcon from 'presentation/components/SectionToolbar/STBIcon';

type ExportReportProps = {
  csvReport: any;
  pdfReport: any;
  showGrid?: boolean;
};

export function ExportReport({ csvReport, pdfReport, showGrid = true }: ExportReportProps): JSX.Element {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const csvRef = useRef();
  const countryLocation = getConnectionLocation();
  const delimitator = countryLocation === 'en-US' ? ',' : ';';

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleExportPdf = () => {
    pdfReport();
    setDropdownOpen(false);
  };

  return (
    <div className="relative" ref={ref}>
      <div className="table_header_button_group">
        {showGrid && <span>Export</span>}
        <div className="table_header_button_group__actions export">
          {showGrid && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Export to CSV (Excel, Google Sheets)</Tooltip>}
            >
              <CSVLink
                ref={csvRef}
                title="Export as CSV"
                separator={delimitator}
                className="table_header_button export-csv"
                target="_blank"
                {...csvReport}
              >
                <IconExportCSV />
              </CSVLink>
            </Whisper>
          )}
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Export as</Tooltip>}>
            {showGrid ? (
              <button
                onClick={() => {
                  setDropdownOpen(status => !status);
                }}
                className="table_header_button small"
              >
                <span className="spacer">
                  <IconDown />
                </span>
              </button>
            ) : (
              <STBIcon
                tooltip="Download Report (CSV or PDF)"
                handleClick={() => {
                  setDropdownOpen(status => !status);
                }}
                icon={<IconDownload />}
              />
            )}
          </Whisper>
        </div>
      </div>
      {dropdownOpen && (
        <div className="export-dropdown">
          {csvReport && (
            <CSVLink
              ref={csvRef}
              title="Export as CSV"
              separator={delimitator}
              className=""
              target="_blank"
              {...csvReport}
            >
              <IconExportCSV />
              CSV (Excel, Google Sheets)
            </CSVLink>
          )}
          <button onClick={handleExportPdf}>
            <IconExportPDF />
            PDF File
          </button>
        </div>
      )}
    </div>
  );
}
